@import '../../../Styles/colors.dashboard.scss';

.background-purple {
  background: rgba(136, 124, 253, 0.3) !important;
  .icon-section {
    background: rgba(136, 124, 253, 1);
    border-radius: 7px;
  }
}

.background-blue {
  background: rgba(72, 150, 254, 0.3) !important;
  .icon-section {
    background: rgba(72, 150, 254, 1);
    border-radius: 7px;
  }
}

.background-green {
  background: rgba(22, 200, 199, 0.3) !important;
  .icon-section {
    background: rgba(22, 200, 199, 1);
    border-radius: 7px;
  }
}

.background-red {
  background: rgba(255, 99, 97, 0.3) !important;
  .icon-section {
    background: rgba(255, 99, 97, 1);
    border-radius: 7px;
  }
}


.statistic-card-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 11rem;
  border-radius: 0.625rem;
  padding: 1rem 0.813rem 0 1.438rem;

  .title-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 3.3rem;

    .title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      font-family: 'Inter';
      font-size: 1.25rem;
      font-weight: 600;
      width: 83%;
      color: rgba(58, 53, 65, 0.8);
    }

    .info-container {
      display: flex;
      flex-direction: row;
      width: 8%;
      align-items: center;
      justify-content: flex-end;
      padding-top: 6px;
      margin-left: 10px;
    }



    .icon-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50px;
      justify-content: center;
      height: 50px;
      color: white;

      svg {
        height: 30px;
        width: 30px;
        fill: white;
      }
    }
  }

  .values-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: 5rem;
    // margin-right: 1rem;

    .values-and-unit {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 60%;

      .value {
        font-family: 'Inter';
        font-size: 2.5rem;
        font-weight: 600;
        width: 100%;
        color: rgba(58, 53, 65, 0.8);
        padding: 0;
      }

      .unit {
        margin-bottom: -0.6rem;
        margin-left: 0.2rem;
        margin-top: -0.66rem;
        font-family: 'Inter';
        font-size: 0.875rem;
        font-weight: 500;
        width: 100%;
        color: rgba(58, 53, 65, 0.5);
      }
    }

    .updated-on-null {
      display: flex;
      flex-direction: row;
      font-size: 0.813rem;
      border-radius: 3.125rem;
      padding: 0 0.5rem 0 0.5rem;
      margin-top: 1rem;
      color: transparent;
      background-color: transparent;
    }
  }

  .updated-on-container {
    display: flex;
    justify-content: flex-end;
    
    .updated-on {
      display: flex;
      flex-direction: row;
      font-size: 0.75rem;
      border-radius: 3.125rem;
      padding: 0.05rem 0.8rem 0.05rem 0.8rem;
      width: max-content;
      color: $primary-blue;
      background-color: #ffffff;
      line-height: 2.1;
      margin-bottom: 15px;
    }
  }

  
}

.statistics-card-main-container:hover {
  box-shadow: rgba(0, 5, 18, 0.2) 0px 8px 24px;
}
