@import '../../Styles/colors.addProgramme.scss';
@import '../../Styles/colors.role.scss';
@import '../../Styles/variables.scss';

.site-check-list-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 50px;

  .title-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .main {
      font-size: 1.25rem;
      font-weight: 500;
      color: $primary-color;
    }

    .sub {
      font-size: 0.8rem;
      color: $title-sub;
    }
  }

  .forms-container {
    display: flex;
    flex-direction: column;
    background-color: $common-form-white;
    margin: 1rem 0 1rem 0;
    border-radius: 10px;
    padding: 2.5rem;
  }

  .stepper-container {
    display: flex;
    margin-bottom: 2rem;
  }
}

.site-check-list-form {
  width: 100;

  .ant-picker {
    width: 100%;
  }

  .plant-factor {
    .ant-input-group-addon {
      border: 1px solid #d9d9d9 !important;
      border-radius: 5px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .custom-required:after {
    content: '*';
    color: rgba(255, 77, 79, 0.8) !important;
    font-size: 14px;
    top: 2px;
    position: relative;
    left: 3px;
  }

  .section-title {
    font-weight: 600;
    color: #3a3541cc;
  }

  .projectTrack {
    width: full;
    display: flex;
    flex-direction: row;
    .ant-form-item {
      width: 40%;
    }
  }

  .checklist-table-form {
    .header {
      background-color: #f9fafc;
      text-align: center;
      padding: 20px 0;
      font-weight: 600px;
    }

    // .col-1 {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   justify-content: center;
    //   font-weight: 600;
    // }

    .data-row {
      padding: 15px 0;
    }
    .col-1 {
      // text-align: left;
      font-weight: 400;
      display: flex;
      align-items: center;
      // padding: 15px 0;

      .text-row {
        display: flex;
        flex-direction: row;

        div:first-child {
          margin-right: 5px;
        }
      }
    }
  }

  .stakeholders-section {
    background-color: #fbfbfb;
    padding: 20px 16px;
  }

  .stakeholders-title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    // align-items: center;
  }

  .step-actions {
    &-end {
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .validator-info {
    .design-upload-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      width: 100%;

      .icon-color {
        color: $primary-color !important;
      }

      .ant-upload {
        width: 20%;

        .ant-btn {
          height: 40px;
        }
      }

      .ant-upload-list {
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 40px;

        .ant-upload-list-picture-container {
          margin-bottom: 15px;

          .ant-upload-list-item-thumbnail {
            height: auto !important;
            img {
              height: auto;
            }
          }
        }

        .ant-upload-list-item {
          margin-top: 0;
          height: 40px;
        }
      }
    }
  }
}
