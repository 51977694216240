$admin-bg-color: rgba(255, 166, 166, 0.42);
$admin-color: #d12800;

$root-bg-color: rgba(255, 0, 229, 0.15);
$root-color: #db00ff;

$manager-bg-color: rgba(96, 27, 209, 0.13);
$manager-color: #75009e;

$view-bg-color: rgba(176, 190, 197, 0.23);
$view-color: #707070;

$gov-bg-color: rgba(185, 226, 244, 0.56);
$gov-color: #16b1ff;

$cf-bg-color: rgba(0, 153, 0, 0.12);
$cf-color: #009900;

$ex-bg-color: rgba(153, 153, 153, 0.12);
$ex-color: #000000;

$dev-bg-color: rgba(254, 241, 173, 0.55);
$dev-color: #ffa070;

$cert-bg-color: rgba(128, 255, 0, 0.12);
$cert-color: #519e4f;

$ministry-bg-color: rgba(198, 144, 251, 0.12);
$ministry-color: #9155fd;
