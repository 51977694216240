.organisation-sl-status-active {
    border-radius: 20px;
    background-color: rgba(#b9e2f4, 0.4);
    padding: 4px 15px;
    height: 28px;
    color: #16b1ff;
    margin-left: 15px;
    margin-right: auto;
    margin-top: -2px;
    width: 90px;
    text-align: center;
  }
  
  .organisation-sl-status-deauthorised {
    border-radius: 20px;
    background-color: rgba(#ffa6a6, 0.4);
    padding: 4px 15px;
    height: 28px;
    color: #ff4d4f;
    margin-left: 15px;
    margin-right: auto;
    margin-top: -2px;
    width: 110px;
    text-align: center;
  }

  .organisation-sl-status-pending {
    border-radius: 20px;
    background-color: rgba(#b089f4, 0.4);
    padding: 4px 15px;
    height: 28px;
    color: #976ed7;
    margin-left: 15px;
    margin-right: auto;
    margin-top: -2px;
    width: 90px;
    text-align: center;
  }

  .organisation-sl-status-rejected {
    border-radius: 20px;
    background-color: rgba(#fff7e6, 0.4);
    padding: 4px 15px;
    height: 28px;
    color: #ff8f56;
    margin-left: 15px;
    margin-right: auto;
    margin-top: -2px;
    width: 90px;
    text-align: center;
    background: #fff7e6;
    border-color: #ffd591;
  }
  