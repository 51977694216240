@import '../../Styles/colors.addProgramme.scss';
@import '../../Styles/colors.role.scss';
@import '../../Styles/variables.scss';

.validation-agreement-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 50px;

  .title-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .main {
      font-size: 1.25rem;
      font-weight: 500;
      color: $primary-color;
    }

    .sub {
      font-size: 0.8rem;
      color: $title-sub;
    }
  }

  .agreement-container {
    display: flex;
    flex-direction: column;
    background-color: $common-form-white;
    margin: 1rem 0 1rem 0;
    border-radius: 10px;
    padding: 2.5rem;
  }
}

.agreement-form {
  width: 100%;

  .step-actions {
    &-end {
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .witness-input {
    label {
      color: black;
    }
  }

  .date-of-issue {
    .ant-picker {
      width: 50%;
    }
  }

  .full-width-form-item {
    width: 100%;
  }

  .section-title {
    color: #282828;
    font-weight: 600px;
    font-size: 16px;
  }

  .section-list {
    ul {
      list-style: none;
      padding-left: 0px;
      font-weight: 600;
      color: #3a3541cc;
      font-size: 16px;

      .inner-list {
        list-style: none;
        padding-left: 40px;
        font-size: 15px;
      }
    }
  }

  .section-description {
    font-weight: 600;
    color: #3a3541cc;
    font-size: 16px;
  }

  .no-margin-p {
    margin: 0 !important;
  }

  .settlement-fee-label {
    &::after {
      content: '*';
      color: rgba(255, 77, 79, 0.8) !important;
      font-size: 14px;
      top: 0px;
      position: relative;
      left: 3px;
    }
  }
  .settlement-fee {
    display: flex;
    flex-direction: row;

    .settlement-fee-input {
      margin-left: 16px;
      margin-right: 16px;

      .ant-form-item-explain-error {
        font-weight: 400 !important;
      }
    }
  }

  .signatures-annexures {
    font-size: 14px;
    font-weight: 600px;

    .custom-required {
      color: rgba(58, 53, 65, 0.5);
    }

    .custom-required:after {
      content: '*';
      color: rgba(255, 77, 79, 0.8) !important;
      font-size: 14px;
      top: 2px;
      position: relative;
      left: 3px;
    }

    .annexures {
      color: #3a3541cc;
    }
    .client-behalf {
      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }
    .signature-upload {
      margin-top: 32px !important;
      .ant-form-item {
        margin-bottom: 0px !important;
      }
    }
    .authorized-signatory {
      margin-top: 8px;
      p {
        margin: 0 !important;
      }
    }

    .design-upload-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      width: 100%;

      .icon-color {
        color: $primary-color !important;
      }

      .ant-upload {
        width: 20%;

        .ant-btn {
          height: 40px;
        }
      }

      .ant-upload-list {
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 40px;

        .ant-upload-list-picture-container {
          margin-bottom: 15px;

          .ant-upload-list-item-thumbnail {
            height: auto !important;
            img {
              height: auto;
            }
          }
        }

        .ant-upload-list-item {
          margin-top: 0;
          height: 40px;
        }
      }
    }
  }

  // p {
  //   margin: 0 !important;
  //   padding: 0 !important;
  // }
}
