@import '../../Styles/colors.addProgramme.scss';
@import '../../Styles/colors.role.scss';
@import '../../Styles/variables.scss';

.validation-form-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 50px;

  .title-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .main {
      font-size: 1.25rem;
      font-weight: 500;
      color: $primary-color;
    }

    .sub {
      font-size: 0.8rem;
      color: $title-sub;
    }

    .version-selector {
      .ant-select-selector {
        border-radius: 8px;
        .ant-select-selection-item {
          color: #000000 !important;
        }
      }
    }
  }

  .forms-container {
    display: flex;
    flex-direction: column;
    background-color: $common-form-white;
    margin: 1rem 0 1rem 0;
    border-radius: 10px;
    padding: 2.5rem 1.5rem 2.5rem 1.5rem;
  }

  .stepper-container {
    display: flex;
    margin-bottom: 2rem;
  }
}

.val-report-step-form-container {
  width: 100%;

  .form-section-title {
    margin-bottom: 20px;
    margin-top: 30px;
    color: rgba(58, 53, 65, 0.5);
    font-weight: 400;
  }

  .list-item-title {
    color: rgba(58, 53, 65, 0.5);
    font-weight: 400;
  }

  .custom-required {
    color: rgba(58, 53, 65, 0.5);
  }
  .custom-required:after {
    content: '*';
    color: rgba(255, 77, 79, 0.8) !important;
    font-size: 14px;
    top: 2px;
    position: relative;
    left: 3px;
  }

  .form-section {
    background-color: #fbfbfb;
    padding: 25px 30px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .form-item-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .radio-btn-flex-row {
    display: flex;
    flex-direction: row;
    width: 20%;
  }
  .crediting-period-title {
    color: rgba(58, 53, 65, 0.5);
    font-weight: 400;
  }
  .crediting-period-flex-row {
    display: flex;
    gap: 10px;
    flex-direction: row;
    // align-items: center;
    p {
      margin-top: 6px;
      color: rgba(58, 53, 65, 0.5);
    }

    .crediting-datepicker {
      width: 50%;
    }
  }
  .half-width-form-item {
    width: 40%;
  }

  .form-list-actions {
    display: flex;
    flex-direction: row;
    // align-items: center;

    h4 {
      margin-top: 6px;
      margin-right: 20px;
    }
    button {
      margin-right: 20px;
    }

    .ant-btn-lg {
      padding: 0 15px !important;
    }
  }
  .custom-label {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      margin-bottom: 10px;
    }
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: #ff4d4f !important;
  }

  .ant-picker-large {
    height: 40px !important;
    width: 100%;
  }

  .ant-upload-list-item-name {
    color: $primary-color;
  }

  .step-actions {
    &-end {
      button {
        margin-left: 10px;
      }
    }
  }

  .full-width-form-item {
    width: 100%;
  }

  .row {
    width: 100%;
    // .step-form-right-col {

    // }
  }
}

.ant-form-item-tooltip {
  cursor: default !important;
}

// .ant-tooltip {
//   // width: 900px !important;
//   .ant-tooltip-content {
//     width: 600px !important;
//     li {
//       list-style-position: inside;
//       list-style: disc;
//     }
//   }
// }

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  grid-template-rows: repeat(2, 1fr);
  column-gap: 20px; /* Space between columns */
  row-gap: 5px;
}

.side-by-side-form-item {
  display: flex;
  justify-content: space-between;
}

.technical-project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  grid-template-rows: repeat(2, 1fr);
  column-gap: 20px; /* Space between columns */
  row-gap: 5px;
}

.technical-project-description {
  background-color: #e8e8e8 !important;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding-top: 15px;
}

.technical-project-grid-title {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 columns with equal width */
  column-gap: 20px;
}

.technical-project-grid-parameter {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 columns with equal width */
  grid-template-rows: repeat(2, 1fr);
  column-gap: 20px; /* Space between columns */
  row-gap: 5px;
}

.table-header {
  padding: 20px 0px;
  // text-align: left;
  font-weight: 600;
  font-size: 15px;
  color: #28282899;
  background-color: #fbfbfb;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-col {
    text-align: center;
  }
}

.ant-input-number-input {
  text-align: left;
}

.validation-conclusion {
  display: flex;
  flex-direction: column;
}

.addMinusBtn {
  height: 40px !important;
  // color: #16b1ff;
  // border: 1px solid #16b1ff;
}

.deskReviewCategory {
  vertical-align: text-top;
}

.followup-interviews-table {
  .ant-table-tbody {
    td {
      vertical-align: top;
    }
  }
}

.custom-required-only:after {
  content: '*';
  color: rgba(255, 77, 79, 0.8) !important;
  font-size: 14px;
  top: 2px;
  position: relative;
  left: 3px;
}

.applicability-table .ant-table-cell {
  vertical-align: top;
}
