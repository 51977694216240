@import '../../Styles/colors.dashboard.scss';

.map-box-container {
  .mapboxgl-popup-content {
    background-color: $white !important;
    color: $card-title !important;
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: $white !important;
  }

  .mapboxgl-popup-close-button,
  .mapboxgl-popup-close-button:hover {
    display: none;
  }
}

.multiple-location-info-container {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  padding: 10px 5px 10px 10px;
  margin-bottom: 10px;
}

.multiple-location-details {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  color: rgba(58, 53, 65, 0.5);
  margin-bottom: 10px;
  p {
    margin-bottom: 0px;
  }

  .coordinates {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 77%;
  }

  .delete-icon {
    margin-top: 10px;
    color: rgb(241, 57, 57);
  }
}
